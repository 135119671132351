import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import InsightWide from "../images/insights/careers-hiring.jpg"

const Insight = () => (
  <div class="insights">
    <Header useLinks={true} />
    <section className="insight-post">
      <div className="careers-header">
        <h1>Join our Team</h1>
        <h4>Last Updated: Jan 2, 2021</h4>
      </div>
      <div
        className="featured-img careers"
        style={{ backgroundImage: "url(" + InsightWide + ")" }}
      ></div>

      <div className="container">
        <h2 style={{ marginTop: 40 }}>Working @ Tx</h2>
        <p>
          We are a welcoming company dedicated to creating technology that
          accelerates our customers business outcomes and disrupts markets. Our
          offices are located in Poland but we consider ourselves a remote first
          company fostering home-office working environment and work-life
          balance. The combination of exciting, challenging product and services
          portfolio and a flexible working enviornment is how we believe we can
          attract and retain the best talent in the industry.
        </p>

        <h3>If you're looking for</h3>
        <ul>
          <li>
            A place you can build amazing things with cutting edge technology
          </li>
          <li>
            A place you can learn from the best architects, developers,
            designers, testers
          </li>
          <li>
            A palce that offers a balance of autonomy and collaborative teamwork
          </li>
          <li>A place that will challange and motivate to spur your growth</li>
        </ul>

        <div className="call-to-action">
          <a href="mailto:contact@techscoped.com" className="button">
            Then Let's talk!
          </a>
        </div>
      </div>
    </section>
    <Footer useLinks={true} />
  </div>
)

export default Insight
